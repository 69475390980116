<template>
  <div class="history-asset">
    <b-card title="Lịch sử tài sản">
      <div class="search-history-asset">
        <b-row>
          <b-col lg="4" md="4" xs="12">
            <b-form-group>
              <label class="font-weight-bold font-size-label-custom">Phòng ban</label>
              <v-select v-model="modelSearch.organizationBranchId" label="organizationBranchName" @input="getListAsset" :options="listDepartment" :reduce="(item) => item.id"></v-select>
            </b-form-group>
          </b-col>
          <b-col lg="3" md="3" xs="12">
            <b-form-group>
              <label class="font-size-label-custom font-weight-bold">Tài sản</label>
              <v-select v-model="modelSearch.deviceId" @input="getListHistory" :options="listAsset" label="type" :reduce="(idAsset) => idAsset.id">
                <span slot="no-options" @click="$refs.select.open = false">
                    Không có dữ liệu
                  </span>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col lg="3" md="3" xs="12">
            <div class="search-date">
              <b-row>
                <b-col lg="6">
                  <b-form-group>
                    <label class="font-weight-bold font-size-label-custom">Từ ngày</label>
                    <flat-pickr v-model="modelSearch.startDate" @input="onValidateDate"
                        class="form-control"
                        :config="configFlatpickr"
                    />
                  </b-form-group>
                </b-col>
                <b-col lg="6">
                  <b-form-group>
                    <label class="font-weight-bold font-size-label-custom">Đến ngày</label>
                    <flat-pickr v-model="modelSearch.endDate" @input="onValidateDate"
                        class="form-control"
                        :config="configFlatpickr"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col lg="2" md="2" xs="12">
            <div class="acction-search-history">
              <b-button @click="getListHistory"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
              >
                <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                />
                <span class="align-middle">Tìm kiếm</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col lg="12" md="12" sm="12" cols="12" xl="12">
          <div class="data-table-history-asset mt-2">
            <span class="custom-span">Có tổng số {{totalCount}} bản ghi</span>
              <b-table :items="listHistoryAsset"
                         :fields="fieldHistory"
                         :per-page="perPage"
                         :current-page="currentPage">
                  <template #cell(index)="data">
                    <span>{{data.index + 1}}</span>
                  </template>
                  <template #cell(historyType)="data">
                    <b-badge v-if="data.value === '4'" variant="danger">
                      Thu hồi
                    </b-badge>
                    <b-badge v-if="data.value === '5'" variant="success">
                      Thêm mới
                    </b-badge>
                    <b-badge v-if="data.value === '3'" variant="info">
                      Cấp phát
                    </b-badge>
                  </template>
                  <template #cell(fullName)="data">
                    <span v-if="data.item.fullName">{{data.item.employeeCode}} - {{data.item.fullName}}</span>
                  </template>
                </b-table>
              <div class="pagination-history-asset">
                <b-pagination
                    v-model="currentPage"
                    :per-page="perPage"
                    hide-goto-end-buttons
                    :total-rows="rows"
                />
              </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  VBTooltip, BFormGroup, BRow, BCol, BTable, BPagination, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import flatPickr from 'vue-flatpickr-component'
import useJwt from '@/auth/jwt/useJwt'
import * as moment from 'moment'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BCard,
    BButton,
    BFormGroup,
    BRow,
    BCol,
    BTable,
    BPagination,
    vSelect,
    flatPickr,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      listDepartment: [],
      listAsset: [],
      listHistoryAsset: [],
      isValidateDate: false,
      modelSearch: {
        deviceId: null,
        deviceType: 0,
        endDate: '',
        organizationBranchId: null,
        startDate: '',
      },
      totalCount: 0,
      currentPage: 1,
      listAllAsset: [],
      perPage: 10,
      fieldHistory: [
        {
          key: 'index', label: 'Stt', thClass: 'custom-th-index',
        },
        {
          key: 'type', label: 'Tài sản',
        },
        {
          key: 'serialNumber', label: 'Số serial', thClass: 'custom-th-serial-number',
        },
        {
          key: 'fullName', label: 'Nhân viên', thClass: 'custom-th-employee',
        },
        {
          key: 'historyType', label: 'Trạng thái', thClass: 'custom-th-status',
        },
        {
          key: 'createdDate', label: 'Thời gian cấp', thClass: 'custom-th-date',
        },
      ],
      configFlatpickr: {
        wrap: true,
        altFormat: 'd/m/Y',
        altInput: true,
        locale: Vietnamese,
        allowInput: true,
      },
    }
  },
  methods: {
    getListDepartment() {
      this.$crm.get('organization-branch-school/find-all-department').then(res => {
        this.listDepartment = res.data
      })
    },
    getListHistory() {
      if (this.modelSearch.endDate !== '' && this.modelSearch.startDate !== '') {
        const startDate = new Date(this.modelSearch.startDate)
        const endDate = new Date(this.modelSearch.endDate)
        if (startDate > endDate) {
          this.isValidateDate = true
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Lỗi!',
              icon: 'XIcon',
              variant: 'danger',
              text: 'Từ ngày phải nhỏ hơn đến ngày',
            },
          })
          return
        }
        if (endDate > startDate) {
          this.isValidateDate = false
        }
      }
      if (this.modelSearch.startDate !== '') {
        this.modelSearch.startDate = moment(this.modelSearch.startDate).format('yyyy-MM-DDT00:00:00')
      }
      if (this.modelSearch.endDate !== '') {
        this.modelSearch.endDate = moment(this.modelSearch.endDate).format('yyyy-MM-DDT23:59:59')
      }
      this.$crm.post('device/find-device-history', this.modelSearch).then(res => {
        this.listHistoryAsset = res.data
        this.totalCount = this.listHistoryAsset.length
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.listHistoryAsset.length; i++) {
          this.listHistoryAsset[i].createdDate = moment(this.listHistoryAsset[i].createdDate).format('DD-MM-YYYY')
        }
      })
    },
    onValidateDate() {
      if (this.modelSearch.startDate !== '' && this.modelSearch.endDate !== '') {
        const startDate = new Date(this.modelSearch.startDate)
        const endDate = new Date(this.modelSearch.endDate)
        if (startDate > endDate) {
          this.isValidateDate = true
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Lỗi!',
              icon: 'XIcon',
              variant: 'danger',
              text: 'Từ ngày phải nhỏ hơn đến ngày',
            },
          })
        } else {
          this.isValidateDate = false
        }
      }
    },
    getListAllAsset() {
      this.modelSearch.deviceId = null
      this.$crm.post('device/find-by-condition', this.modelSearch).then(res => {
        this.listAsset = res.data
      })
    },
    getListAsset() {
      this.listAsset = []
      this.modelSearch.deviceId = null
      if (this.modelSearch.organizationBranchId === null) {
        this.getListAllAsset()
        this.getListHistory()
      } else {
        this.getListHistory()
        this.$crm.post('device/find-by-condition', this.modelSearch).then(res => {
          this.listAsset = res.data
        })
      }
    },
  },
  created() {
    this.getListDepartment()
    this.getListHistory()
    if (this.modelSearch.organizationBranchId === null) {
      this.getListAllAsset()
    }
  },
  computed: {
    rows() {
      return this.listHistoryAsset.length
    },
  },
}
</script>

<style lang="scss">
@import '../../@core/scss/custom/history-asset.scss';
@import 'src/@core/scss/vue/libs/vue-select.scss';
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
</style>
